
























import {
  computed,
  defineComponent,
  PropType,
  useStore,
} from '@nuxtjs/composition-api'
import { TCardAchievement } from '@/contracts/Cards'
import SectionBasic from '@/components/SectionBasic.vue'
import { SectionData } from '@/contracts/SectionData'
import CardAchievement from '@/components/Cards/CardAchievement.vue'

export default defineComponent({
  name: 'SectionAchievements',
  components: { CardAchievement, SectionBasic },
  props: {
    sectionData: {
      type: Object as PropType<SectionData>,
      default: () => ({}),
    },
    background: {
      type: String,
      default: '',
    },
    cardList: {
      type: Array as PropType<TCardAchievement[]>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore()

    const styles = computed(() => ({
      '--section-achievement-background':
        props.background || 'var(--bg-secondary)',
    }))

    const isMobile = computed(() => store.getters['mediaQuery/isMobileMq'])

    return {
      isMobile,
      styles,
    }
  },
})
