



















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { TCardAchievement } from '@/contracts/Cards'

export default defineComponent({
  name: 'CardAchievement',
  props: {
    card: {
      type: Object as PropType<TCardAchievement>,
      default: () => ({}),
    },
  },
  setup(props) {
    const styles = computed(() => ({
      '--card-background': props.card.background || 'inherit',
      '--card-icon-color': props.card.iconColor || 'var(--primary)',
    }))
    return {
      styles,
    }
  },
})
